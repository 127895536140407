// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "bootstrap"
import $ from 'jquery'
import ahoy from "ahoy.js"


$(function() {
  if($('#modalButton').data('results')) {
    $('#modalButton').click();
  }
});

const questionWrappers = document.querySelectorAll('.question-wrapper')
questionWrappers.forEach(function(wrapper, index) {
  wrapper.querySelectorAll('.category-item').forEach(item => {
    item.addEventListener('click', function(){
      wrapper.dataset.clicked = true
        const questionWrappers = document.querySelectorAll('.question-wrapper')
        const clickedWrappers = Array.from(questionWrappers).map(radio => {
          return radio.dataset.clicked
        }).filter(e => e == 'true')

        if(clickedWrappers.length == 9) {
          document.querySelector('.questionnaire-submit').disabled = false
        }
        if(wrapper.dataset.clicked == 'true' && questionWrappers[index + 1]) {
          questionWrappers[index + 1].classList.remove('wrapper-opacity')
        }
    })
  })
})


const b = document.getElementById('results-share');
if (b) {
  b.addEventListener(('click'), function() {
    ahoy.trackClicks("#results-share");
    b.insertAdjacentHTML('afterbegin', '<div id="flash-clipboard">Copied to clipboard!</div>')
    const c = document.getElementById('flash-clipboard')
    setTimeout(() => {c.remove()}, 3000);
  })
}
